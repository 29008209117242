import React from "react";
import FundName from "../FundName/FundName";
const FundCompany = (props) => {
  const { funds, oktaUser, auth0User } = props;
  if (funds.length === 0) {
    return (
      <p data-testid="iip-no-results">
        Sorry, your search did not return any results. Please try again.
      </p>
    );
  }
  return (
    <>
      {funds.map((fund) => {
        return (
          <div key={fund.fundCompany} className="mm-spacing">
            <h2 data-testid="iip-fund-company">{fund.fundCompany}</h2>
            <hr />
            <FundName
              details={fund.funds}
              oktaUser={oktaUser}
              auth0User={auth0User}
            />
          </div>
        );
      })}
    </>
  );
};

export default FundCompany;
