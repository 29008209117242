import axios from "axios";
import React, { useEffect, useState } from "react";
import tealiumPageViewEvent from "../../tealiumPageViewEvent";
import Contact from "../Contact/Contact";
import DashBoardTopContent from "../DashBoardTopContent/DashBoardTopContent";
import Disclaimer from "../Disclaimer/Disclaimer";
import FormsCenter from "../FormsCenter/FormsCenter";
import IIPCollapse from "../IIPCollapse/IIPCollapse";
import PrivateClientGroup from "../PrivateClientGroup/pvtClientGroup";
import Products from "../Product/Products";
import ServiceMarketingTools from "../ServiceMarketingTools/ServiceMarketingTools";
import Alerts from "../Alerts/Alerts";

const Home = (props) => {
  const [content, setContent] = useState({});
  const [err, setErr] = useState(false);
  const [spinner, setSpinner] = useState(true);

  const {
    servicemarketTool,
    products,
    firstName,
    path,
    active,
    pvtClientGroup,
    onlySL14,
    onlyBoli,
    boliProducts,
    rightType
  } = props;

  useEffect(() => {
    axios
      .get("/institutional-insurance/getContent")
      .then((res) => {
        setContent(res.data);
        setSpinner(false);
        tealiumPageViewEvent(
          "Insurance Portal Dashboard",
          "content",
          "Dashboard Page"
        );
      })
      .catch((error) => {
        setErr(error);
        setSpinner(false);
      });
  }, [err]);

  // Set Fund Categories based on the funds information recevied from the server

  if (spinner) {
    return (
      <div className="mm-page-is-loading" data-testid="iip-spinner">
        <div
          className="mm-dots-loading-xl"
          role="img"
          aria-label="MassMutual Logo"
        />
        <span className="sr-only" aria-busy="true" aria-live="assertive">
          Page is loading
        </span>
      </div>
    );
  }

  if (err) {
    return (
      <div className="container">
        <div
          className="alert alert-danger"
          role="alert"
          data-testid="iip-alert-error"
        >
          Server Error. Please try again.
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container">
        {content.show_alert_messages && <Alerts rightType={rightType} products={products} alertsContent={content.alert_message_list} />}
        <div className="row mm-spacing">
          <div
            className="col-xs-12 col-sm-8"
            data-testid="iip-dashboard-topcontent"
          >
            <DashBoardTopContent
              content={content.dashboard}
              firstName={firstName}
              sl14Only={onlySL14}
            />
          </div>
          <div className="col-xs-12 col-sm-4" data-testid="iip-contact">
            <Contact content={content.contact} />
          </div>
        </div>
        <div>
          {pvtClientGroup && (
            <div className="col-xs-12" data-testid="iip-pvtClientGroup">
              <PrivateClientGroup path={path} />
            </div>
          )}
          {boliProducts && (
            <div className="col-xs-12" data-testid="iip-formcenter">
              <FormsCenter
                path={path}
                servicemarketTool={servicemarketTool}
                noAccessTextBoli={content.dashboard.noAccessTextBoli}
              />
            </div>
          )}
          {servicemarketTool && !onlySL14 && !onlyBoli && (
            <div className="col-xs-12" data-testid="iip-servicingtools">
              <ServiceMarketingTools
                contentTools={content.dashboard.dashboardServicingTools}
                prefixUrl=""
                heading="Servicing Tools"
              />
            </div>
          )}
          {!onlySL14 && !onlyBoli && (
            <div className="col-xs-12" data-testid="iip-products">
              <Products
                products={products}
                content={content}
                path={path}
                active={active}
              />
            </div>
          )}
          {!onlySL14 && !onlyBoli && (
            <div className="col-xs-12 mm-spacing" data-testid="iip-collapse">
              <IIPCollapse
                content={content.dashboard.dashboardPreviouslySold}
                boolcollapse={false}
                prefixUrl=""
                id={"previouslySold"}
                fontSize={"h4"}
                spacing=""
              />
            </div>
          )}

          {servicemarketTool && !onlySL14 && (
            <div className="col-xs-12" data-testid="iip-marketingtools">
              <ServiceMarketingTools
                contentTools={content.dashboard.dashboardMarketingTools}
                prefixUrl={`/institutional-insurance${path}/`}
                heading="Marketing Tools"
                onlyBoli={onlyBoli}
                boliProducts={boliProducts}
              />
            </div>
          )}
        </div>
      </div>
      {!onlyBoli && (
        <Disclaimer
          content={
            onlySL14
              ? content.dashboard.dashboardDisclaimerSL14
              : content.dashboard.dashboardDisclaimer
          }
        />
      )}
      {onlyBoli && (
        <Disclaimer content={content.dashboard.dashboardDisclaimerBoli} />
      )}
    </>
  );
};

export default Home;
