import React, { useEffect, useState } from "react";
import { Alert } from "react-blueprint-library";
import DOMPurify from "dompurify";
import DateUtils from "../../utils/dateManipulation/date-utils";

const Alerts = (props) => {
  const { alertsContent, products, rightType } = props;
  const [isAlertClosed, setIsAlertClosed] = useState({});
  const trueValue = true;

  useEffect(() => {
    if (sessionStorage.getItem("isSessionActive") === null) {
      for (let i = 0; i < alertsContent.length; i += 1) {
        const doesCookieExist = document.cookie.indexOf(`alertMessage-${alertsContent[i].unique_cookie}`);
        if (doesCookieExist !== -1) {
          document.cookie = `alertMessage-${alertsContent[i].unique_cookie}=; Max-Age=0; path=/institutional-insurance;`;
        }
      }
    }
    sessionStorage.setItem("isSessionActive", true);
    for (let i = 0; i < alertsContent.length; i += 1) {
      setIsAlertClosed((prevState) => ({
        ...prevState,
        [alertsContent[i].unique_cookie]: true,
      }));
    }
  }, []);

  const closeAlert = (item) => {
    document.cookie = `alertMessage-${item.unique_cookie}=false;`;
    setIsAlertClosed((prevState) => ({
      ...prevState,
      [item.unique_cookie]: false,
    }));
  };

  const closedStatus = (cookieName) => {
    const browserCookies = document.cookie;
    const cookieFound = browserCookies.split("; ").some((row) => row.includes(`alertMessage-${cookieName}`));
    if (cookieFound) {
      return true;
    }
    return false;
  };

  const filterBy = (alertSLProducts, activeProducts, rightType, alertRightType) => {
    if (rightType.toLowerCase() === alertRightType.toLowerCase() && activeProducts.some((i) => alertSLProducts.includes(i))){
      return true;
    }
    else if (alertRightType === "" && activeProducts.some((i) => alertSLProducts.includes(i))) {
      return true;
    }
    return false;
  };

  const displayAlert = (item) => {
    const isDateRangeNotActive = item.use_date_range === false;
    const isWithinDateRange = DateUtils.isAfterOrSame(new Date(new Date().toDateString()), DateUtils.formatTimezone(new Date(item.message_display_start_date)))
    && DateUtils.isBeforeOrSame(new Date(new Date().toDateString()), DateUtils.formatTimezone(new Date(item.message_display_end_date)));
    const displayDateCondition = isDateRangeNotActive || isWithinDateRange;
    if (item.show_alert_message && displayDateCondition) {
      const isClosed = closedStatus(item.unique_cookie);
      const hasSLProducts = filterBy(item.SLProducts, products, rightType, item.rightType);
      const results = item.showAll ? item.showAll && !isClosed : hasSLProducts && !isClosed;
      return results;
    }
    return false;
  };

  return (
    <>
      {alertsContent.map((item) => {
        return (
          isAlertClosed[item.unique_cookie] && displayAlert(item) &&
            <Alert onClose={() => { closeAlert(item); }} show={displayAlert(item)} transition={trueValue} dismissible={item.dismissable} compact={item.compact} key={item.unique_cookie} type={item.alert_type} id={`alertMessage-${item.unique_cookie}`}> 
              <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.message_description, { ADD_ATTR: ["target"] }) }} />
            </Alert>
        );
      })}
    </>
  )
}

export default Alerts;
