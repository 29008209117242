import React, { useState } from "react";

const Search = (props) => {
  const [showClear, setShowClear] = useState(false);
  const [searchText, setSearchText] = useState("");

  const { searchFilter } = props;

  const onSearchInputChange = (event) => {
    if (event.target.value.trim() === "") {
      setSearchText("");
      searchFilter("");
      setShowClear(false);
    } else {
      setSearchText(event.target.value);
      searchFilter(event.target.value);
      setShowClear(true);
    }
  };
  const onClearBtnClick = () => {
    setSearchText("");
    searchFilter("");
    setShowClear(false);
  };
  return (
    <div className="input-group" data-testid="iip-search">
      <input
        aria-describedby="search-dynamic-addon2"
        aria-label="Search Funds"
        className="form-control"
        id="search-dynamic-clear"
        placeholder="Search"
        type="text"
        value={searchText}
        onChange={(e) => onSearchInputChange(e)}
        data-testid="iip-input"
      />
      <div className="input-group-append">
        <button
          className="btn btn-link"
          type="button"
          style={{ display: showClear ? "inline-block" : "none" }}
          onClick={() => onClearBtnClick()}
          data-testid="iip-clear"
        >
          <span className="sr-only">Clear</span>
          <span
            aria-hidden="true"
            className="icon-answer-incorrect"
            title="Clear"
          ></span>
        </button>
        <span
          className="input-group-text"
          id="search-dynamic-addon2"
          style={{ paddingLeft: showClear ? "0" : "calc(.8rem * 1.5)" }}
        >
          <span className="sr-only">Search</span>
          <span
            className="icon-search-boulder"
            aria-hidden="true"
            title="Search"
          ></span>
        </span>
      </div>
    </div>
  );
};

export default Search;
