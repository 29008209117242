import parse from "html-react-parser";
import React from "react";
import { Link } from "react-router-dom";
import { SL20, SL21 } from "../../IIPconstants";

const Products = (props) => {
  const { products, content, path, active } = props;
  return (
    <div className="mm-spacing">
      <h2 className="h2" data-testid="iip-sub-heading">
        COLI Products
      </h2>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <div className="card-body">
              <h4 className="h4" data-testid="iip-sub-heading">
                Private Placement Variable Universal Life
              </h4>
              <ul className="mm-actions-list" data-testid="mg-actions-list">
                {products.includes(SL20) && active && (
                  <li>
                    <Link
                      data-testid="mg-link-sl20"
                      to={`${path}/product/sl20`}
                    >
                      {content.topContent.titleSl20}
                    </Link>
                  </li>
                )}
                {products.includes(SL21) && active && (
                  <li>
                    <Link
                      data-testid="mg-link-sl21"
                      to={`${path}/product/sl21`}
                    >
                      {content.topContent.titleSl21}
                    </Link>
                  </li>
                )}
                {(!(products.includes(SL20) || products.includes(SL21)) ||
                  !active) &&
                  parse(content.dashboard.noAccessText)}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <div className="card">
            <div className="card-body">
              <h4 className="h4" data-testid="iip-sub-heading">
                {content.dashboard.currentlySoldText}
              </h4>
              <ul className="mm-actions-list">
                {content.dashboard.currentlySold.map((currentObj, index) => {
                  return (
                    <li key={index}>
                      <a href={currentObj.url} target="_blank" rel="noreferrer">
                        {parse(currentObj.currentlySoldProduct)}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
