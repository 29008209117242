import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const FormsCenter = (props) => {
  const { path, servicemarketTool, noAccessTextBoli } = props;
  return (
    <div className="mm-spacing">
      <h2 className="h2" data-testid="iip-sub-heading">
        General Account BOLI
      </h2>
      {servicemarketTool && (
        <div className="card">
          <div className="card-body">
            <ul className="mm-actions-list">
              <li>
                <Link to={`${path}/product/boli`}>Forms Center</Link>
              </li>
            </ul>
          </div>
        </div>
      )}
      {!servicemarketTool && (
        <div className="card">
          <div className="card-body">
            <p>{parse(noAccessTextBoli)}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormsCenter;
