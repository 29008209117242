import React from "react";

const Footer = () => {
  return (
    <section className="mm-footer" data-testid="iip-footer">
      <section className="container mm-footer-legal">
        <div className="row">
          <div className="col-12">
            <div
              className="mm-logo"
              role="img"
              aria-label="MassMutual Logo"
            ></div>
          </div>

          <div className="col-12">
            <p>
              &copy; {new Date().getFullYear()} Massachusetts Mutual Life
              Insurance Company (MassMutual&reg;), Springfield, MA 01111-0001.
              All rights reserved.
            </p>
          </div>

          <div className="col-12">
            <ul>
              <li>
                <a
                  href="https://www.massmutual.com/legal/terms-of-use"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Terms of Use
                </a>
              </li>
              <li>
                <a
                  href="https://www.massmutual.com/legal/privacy"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Privacy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Footer;
