import axios from "axios";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import BoliFormPage from "./components/FormsCenter/BoliFormPage";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import NoAccess from "./components/NoAccess/NoAccess";
import ProductPage from "./components/ProductPage/ProductPage";
import TimeOutModal from "./components/TimeoutModal/TimeoutModal";
import { INTERNAL, SL11, SL14, SL19, SL20, SL21 } from "./IIPconstants";

const redirectLogin = require("./redirect-login");

const App = () => {
  let appEnvironment = "prod";
  if (window.location.hostname.includes("local")) {
    appEnvironment = "local";
  }
  if (window.location.hostname.includes("dev")) {
    appEnvironment = "dev";
  }
  if (window.location.hostname.includes("qa")) {
    appEnvironment = "qa";
  }
  const [oktaUser, setOktaUser] = useState({});
  const [auth0User, setAuth0User] = useState({});
  const [spinner, setSpinner] = useState(true);
  const [active, setActive] = useState(false);
  const [servicemarketTool, setservicemarketTool] = useState(false);
  const [pvtClientGroup, setpvtClientGroup] = useState(false);
  const [onlySL14, setOnlySL14] = useState(false);
  const [onlyBoli, setonlyBoli] = useState(false);
  const [boliProducts, setboliProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [path, setPath] = useState("");
  const pathname = useLocation().pathname.replace(/\/+$/, "");
  const location = useLocation();
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [timer, setTimer] = useState("5:00");
  const [rightType, setRightType] = useState("");
  let intervalId;
  const errorPDF = "You have reached this page in error.";
  const productType = location.pathname.split("/").pop();

  useEffect(() => {
    if (pathname.startsWith(INTERNAL)) {
      axios
        .get("/institutional-insurance/internal/userInformation")
        .then((res) => {
          setOktaUser(res.data);
          setFirstName(res.data.firstname);
        })
        .catch((err) => {
          if (appEnvironment === "prod") {
            console.log("Server error while getting internal information.");
          } else {
            console.log(err.message);
          }
        });
    } else {
      axios
        .get("/institutional-insurance/profile")
        .then((res) => {
          const response = res.data;
          if (response.isUserLoggedIn === true) {
            setAuth0User(response.userInfo);
          } else if (response.isUserLoggedIn === false) {
            redirectLogin("/institutional-insurance/login");
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            redirectLogin("/institutional-insurance/login");
          }
        });
    }
  }, [pathname, appEnvironment]);

  useEffect(() => {
    if (oktaUser.mmid !== undefined) {
      setActive(true);
      setservicemarketTool(true);
      setProducts([SL11, SL14, SL19, SL20, SL21]);
      setpvtClientGroup(true);
      setSpinner(false);
      setPath(INTERNAL);
      setboliProducts(true);
    } else if (auth0User.email !== undefined) {
      const email = auth0User.email;
      axios
        .post("/institutional-insurance/getAccountStatus", {
          userEmail: email,
        })
        .then((response) => {
          setActive(response.data && response.data.active);
          setOnlySL14(
            response.data.productEntitlementsList.length === 1 &&
              response.data.productEntitlementsList.includes("SL14")
          );
          setservicemarketTool(
            response.data.rightType === "Broker" ||
              response.data.rightType === "Tpa"
          );
          setRightType(response.data.rightType);
          setProducts(response.data.productEntitlementsList);
          setpvtClientGroup(
            response.data.productEntitlementsList.includes("SL14")
          );
          setonlyBoli(
            response.data.productEntitlementsList.every(
              (elem) => ["SL11", "SL19"].indexOf(elem) > -1
            ) && response.data.productEntitlementsList.length > 0
          );
          setboliProducts(
            response.data.productEntitlementsList.includes("SL11") ||
              response.data.productEntitlementsList.includes("SL19")
          );
          setFirstName(response.data.firstName);
          setSpinner(false);
          setPath("");
        })
        .catch((error) => {
          if (appEnvironment === "prod") {
            console.log("Server error while getting account status.");
          } else {
            console.log(error.message);
          }
        });
    }
  }, [oktaUser, auth0User, appEnvironment]);

  const onPrompt = () => {
    if (oktaUser && oktaUser.mmid !== undefined) {
      reset();
    }
    if (!showTimeoutModal) {
      intervalId = setInterval(() => {
        const mins = Math.floor(getRemainingTime() / 60000);
        const seconds = Math.round((getRemainingTime() % 60000) / 1000);
        setTimer(mins + ":" + (seconds < 10 ? "0" : "") + seconds);
      }, 1000);
      setShowTimeoutModal(true);
    }
  };

  const onIdle = () => {
    setShowTimeoutModal(false);
    logoutFromAuth0();
  };

  const stayLoggedIn = () => {
    activate();
    clearInterval(intervalId);
    setShowTimeoutModal(false);
  };

  const logoutFromAuth0 = () => {
    let siteUrl = "https://institutional.massmutual.com";
    if (window.location.hostname.includes("local")) {
      siteUrl = "http://localhost:2999";
    }
    if (window.location.hostname.includes("dev")) {
      siteUrl = "https://institutional-dev.massmutual.com";
    }
    if (window.location.hostname.includes("qa")) {
      siteUrl = "https://institutional-qa.massmutual.com";
    }
    const url = `${siteUrl}/institutional-insurance/logout`;
    clearInterval(intervalId);
    setShowTimeoutModal(false);
    window.open(url, "_self");
  };

  const { activate, getRemainingTime, reset } = useIdleTimer({
    name: "idle-timer",
    onIdle,
    onPrompt,
    promptTimeout: 5 * 60 * 1000,
    timeout: 5 * 60 * 1000,
  });

  if (spinner) {
    return (
      <div className="mm-page-is-loading" data-testid="iip-spinner">
        <div
          className="mm-dots-loading-xl"
          role="img"
          aria-label="MassMutual Logo"
        />
        <span className="sr-only" aria-busy="true" aria-live="assertive">
          Page is loading
        </span>
      </div>
    );
  }

  return (
    <>
      <div className="mm-shoes">
        <Header oktaUser={oktaUser} auth0User={auth0User} />
        <main className="main-content" role="main">
          <Routes>
            <Route
              path="/"
              exact
              element={
                <Home
                  rightType={rightType}
                  onlySL14={onlySL14}
                  pvtClientGroup={pvtClientGroup}
                  oktaUser={oktaUser}
                  auth0User={auth0User}
                  servicemarketTool={servicemarketTool}
                  products={products}
                  firstName={firstName}
                  path={path}
                  location={location}
                  active={active}
                  onlyBoli={onlyBoli}
                  boliProducts={boliProducts}
                />
              }
            />
            <Route
              path={INTERNAL}
              exact
              element={
                <Home
                  pvtClientGroup={pvtClientGroup}
                  oktaUser={oktaUser}
                  auth0User={auth0User}
                  servicemarketTool={servicemarketTool}
                  products={products}
                  firstName={firstName}
                  path={path}
                  active={active}
                  boliProducts={boliProducts}
                />
              }
            />
            <Route
              path={`${path}/product/boli`}
              exact
              element={
                boliProducts ? (
                  <BoliFormPage oktaUser={oktaUser} path={path} />
                ) : (
                  <NoAccess />
                )
              }
            />
            <Route
              path={`${path}/product/${productType}`}
              exact
              element={
                products.includes(productType.toUpperCase()) ? (
                  <ProductPage
                    oktaUser={oktaUser}
                    auth0User={auth0User}
                    servicemarketTool={servicemarketTool}
                    products={products}
                    location={location}
                    path={path}
                  />
                ) : (
                  <NoAccess />
                )
              }
            />
          </Routes>
          {pathname.endsWith(".pdf") && (
            <div>
              <section className="container">
                <div className="row">
                  <Link to={path} className="btn btn-link" data-test="mg-back">
                    <span className="icon-arrow-circle-spin-left" />
                    <span>Back To Dashboard</span>
                  </Link>
                </div>
                <h2>{errorPDF}</h2>
              </section>
            </div>
          )}
        </main>
        <Footer />
      </div>
      {auth0User && auth0User.email !== undefined && (
        <TimeOutModal
          showTimeoutModal={showTimeoutModal}
          timer={timer}
          logoutFromAuth0={logoutFromAuth0}
          stayLoggedIn={stayLoggedIn}
        />
      )}
    </>
  );
};

export default App;
