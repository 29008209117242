import React, { useEffect, useState } from "react";
const Checkboxes = (props) => {
  const [categoryArr, setCategoryArr] = useState([]);
  const { categories, checkboxFilter } = props;

  useEffect(() => {
    checkboxFilter(categoryArr);
  }, [categoryArr, checkboxFilter]);

  const changeCheckbox = (e) => {
    let filteredCategoryArr = [...categoryArr];
    if (!categoryArr.includes(e.target.value)) {
      filteredCategoryArr.push(e.target.value);
      setCategoryArr(filteredCategoryArr);
    } else {
      const index = filteredCategoryArr.indexOf(e.target.value);
      filteredCategoryArr.splice(index, 1);
      setCategoryArr(filteredCategoryArr);
    }
  };

  return (
    <div data-testid="iip-checkboxes">
      <p data-testid="iip-title">
        <strong>Fund Categories</strong>
      </p>
      {categories.map((cat, index) => {
        return (
          <div key={index} className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`customCheck${index}`}
              value={cat}
              onChange={(e) => changeCheckbox(e)}
            />
            <label
              className="custom-control-label"
              htmlFor={`customCheck${index}`}
            >
              {cat}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default Checkboxes;
