import React from "react";

const Contact = (props) => {
  const { content } = props;
  return (
    <>
      <div className="card">
        <div className="card-body">
          <h4 className="h4" data-testid="iip-title">
            {content.title}
          </h4>
          <ul className="mm-actions-list">
            <li>
              <span className="icon-call"></span>{" "}
              <a data-testid="iip-phone" href={`tel:${content.phone}`}>
                {content.phone}
              </a>
            </li>
            <li>
              <span className="icon-email"></span>{" "}
              <a data-testid="iip-email" href={`mailto:${content.email}`}>
                {content.email}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Contact;
