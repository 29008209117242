import parse from "html-react-parser";
import React from "react";

const RegisteredInvestment = (props) => {
  const { content, showSLActive } = props;
  let title = "";
  if (showSLActive[0]) {
    title = "Traditional Investment Options";
  } else if (showSLActive[1]) {
    title = "Registered Investment Divisions";
  }

  return (
    <div className="mm-spacing col-sm-12">
      <h4 className="h4" data-testid="iip-registered-heading">
        {title}
      </h4>
      {parse(content.registeredInvestment)}
    </div>
  );
};

export default RegisteredInvestment;
