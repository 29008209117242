import axios from "axios";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { INTERNAL } from "../../IIPconstants";
import tealiumPageViewEvent from "../../tealiumPageViewEvent";
import Contact from "../Contact/Contact";
import Disclaimer from "../Disclaimer/Disclaimer";
import IIPCollapse from "../IIPCollapse/IIPCollapse";
import { ScrollSpy } from "react-blueprint-library";

const BoliFormPage = (props) => {
  const [content, setContent] = useState({});
  const [err, setErr] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const { oktaUser, path } = props;
  const link = Object.keys(oktaUser).length === 0 ? "/" : INTERNAL;
  const PREFIX_URL = `/institutional-insurance${path}/product/boli/`;

  useEffect(() => {
    let getContent = axios.get("/institutional-insurance/getContent");
    getContent
      .then((response) => {
        const content = response;
        setContent(content.data);
        setSpinner(false);
        tealiumPageViewEvent(
          "Insurance Portal Boli Product",
          "Content",
          "Boli Funds Information"
        );
      })
      .catch((error) => {
        setErr(error);
        setSpinner(false);
      });
  }, [err]);

  if (spinner) {
    return (
      <div className="mm-page-is-loading" data-testid="iip-spinner">
        <div
          className="mm-dots-loading-xl"
          role="img"
          aria-label="MassMutual Logo"
        />
        <span className="sr-only" aria-busy="true" aria-live="assertive">
          Page is loading
        </span>
      </div>
    );
  }

  if (err) {
    return (
      <div className="container">
        <div
          className="alert alert-danger"
          role="alert"
          data-testid="iip-alert-error"
        >
          Server Error. Please try again.
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <Link to={link} className="btn btn-link" data-testid="mg-back">
            <span className="icon-arrow-circle-spin-left" />
            <span>Back To Dashboard</span>
          </Link>
        </div>
        <div className="row">
          <div className="col-sm-8" data-testid="iip-generalInfoText">
            <h2 className="h2">Forms Center - General Account BOLI</h2>
            <p>
              {parse(
                content.productPage.BoliProductPage.ProductsInformation
                  .GeneralInfoText
              )}
            </p>
          </div>
          <div className="col-md-4 col-sm-4" data-testid="iip-contact">
            <Contact content={content.contactBoli} />
          </div>
        </div>
        <div className="mm-spacing">
          <ScrollSpy placement="auth">
            <div data-id="products" name="Products">
              <a href="#products" id="products" data-testid="iip-products">
                <p className="h3">Products</p>
              </a>
              <div>
                {content && (
                  <p>
                    {parse(
                      content.productPage.BoliProductPage.ProductsInformation
                        .ProductsInfoText
                    )}
                  </p>
                )}
                <ul className="list-unstyled" style={{ columns: 5 }}>
                  {content.productPage.BoliProductPage.ProductsInformation.ProductsInfoStates.map(
                    (state, i) => {
                      return (
                        <li data-testid="iip-states" key={i}>
                          {state}
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
            {parse(content.productPage.BoliProductPage.BoliContact)}
            <div data-id="generalInformation" name="General Information">
              <a
                href="#generalInformation"
                id="generalInformation"
                data-testid="generalInformationList"
              >
                <p className="h3">General Information</p>
              </a>
              <div>
                {" "}
                <ul>
                  {content.productPage.BoliProductPage.GeneralInformation.map(
                    (generalInfo, i) => {
                      return (
                        <li data-testid="iip-general-bulletpoints" key={i}>
                          {generalInfo}
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
            <div
              data-id="boliCommonServiceForms"
              name="BOLI Common Service Forms"
            >
              <a
                href="#boliCommonServiceForms"
                id="boliCommonServiceForms"
                data-testid="commonServiceForm"
              >
                <p className="h3">BOLI Common Service Forms</p>
              </a>
              <div>
                {Object.keys(
                  content.productPage.BoliProductPage.CommonServiceForms
                ).map((type, i) => {
                  return (
                    <IIPCollapse
                      datatestid={"iip-commonServiceForm"}
                      content={
                        content.productPage.BoliProductPage.CommonServiceForms[
                          type
                        ]
                      }
                      key={i}
                      boolcollapse={true}
                      prefixUrl={PREFIX_URL}
                      spacing={
                        content.productPage.BoliProductPage.CommonServiceForms[
                          type
                        ].text == "Other Service Form"
                          ? "mm-spacing"
                          : ""
                      }
                      id={type}
                      fontSize={"h5"}
                    />
                  );
                })}
              </div>
            </div>
            <div
              data-id="boliTimeOfApplicationForms"
              name="BOLI Time of Application Forms"
            >
              <a
                href="#boliTimeOfApplicationForms"
                id="boliTimeOfApplicationForms"
                data-testid="timeOfApplicationForm"
              >
                <p className="h3">BOLI Time Of Application Forms</p>
              </a>
              <div>
                {Object.keys(
                  content.productPage.BoliProductPage.TimeOfApplicationForms
                ).map((type, i) => {
                  return (
                    <IIPCollapse
                      datatestid={"iip-timeOfApplicationForm"}
                      content={
                        content.productPage.BoliProductPage
                          .TimeOfApplicationForms[type]
                      }
                      key={i}
                      boolcollapse={true}
                      prefixUrl={PREFIX_URL}
                      spacing={
                        content.productPage.BoliProductPage
                          .TimeOfApplicationForms[type].text ==
                        "Miscellaneous Forms (Required for all cases at time of application)"
                          ? "mm-spacing"
                          : ""
                      }
                      id={type}
                      fontSize={"h5"}
                    />
                  );
                })}
              </div>
            </div>
            <div
              data-id="boliFormsRequiredforUnderwriting"
              name="BOLI Forms Required for Underwriting"
            >
              <a
                href="#boliFormsRequiredforUnderwriting"
                id="boliFormsRequiredforUnderwriting"
                data-testid="boliFormUnderwriting"
              >
                <p className="h3">BOLI Forms Required for Underwriting</p>
              </a>
              <div>
                {Object.keys(
                  content.productPage.BoliProductPage.BoliFormUnderwriting
                ).map((type, i) => {
                  return (
                    <IIPCollapse
                      datatestid={"iip-boliFormUnderwriting"}
                      content={
                        content.productPage.BoliProductPage
                          .BoliFormUnderwriting[type]
                      }
                      key={i}
                      boolcollapse={true}
                      prefixUrl={PREFIX_URL}
                      spacing=""
                      id={type}
                      fontSize={"h5"}
                    />
                  );
                })}
              </div>
            </div>
          </ScrollSpy>
        </div>
        <Disclaimer content={content.dashboard.dashboardDisclaimerBoli} />
      </div>
    </>
  );
};

export default BoliFormPage;
