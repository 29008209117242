/**
 * Pads a 0 to one digit numbers (ie 2 becomes 02, 12 stays 12)
 * For Months it is better to keep it as a 2 digit value as Date can sometimes confuse what the value is for
 *
 * @param {int/String} num the number to pad
 */
const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};
  
const convertToDateFormat = (string) => {
  const day = string.substr(3, 2);
  const month = string.substr(0, 2);
  const year = string.substr(6, 4);
  return `${year}-${month}-${day}`;
};
/**
* This function subtracts months from a date, returns Date Object
*
* @param {Date} date the date  object to subtract from
* @param {int} months number of months to subtract
*/
const subtractMonths = (date, months) => {
  date.setMonth(date.getMonth() - months);
  return date;
};
/**
* This function adds days to a date, returns Date Object
*
* @param {Date} date the date object to add to
* @param {int} days number of months to add
*/
const addDays = (date, days) => {
  date.setDate(date.getDate() + days);
  return date;
};
/**
* This function adds months to a date, returns Date Object
*
* @param {Date} date the date object to add to
* @param {int} months number of months to add
*/
const addMonths = (date, months) => {
  date.setMonth(date.getMonth() + months);
  return date;
};
/**
* This function subtracts years from a date, returns Date Object
*
* @param {Date} date the date object to subtract from
* @param {int} years number of years to subtract
*/
const subtractYears = (date, years) => {
  date.setFullYear(date.getFullYear() - years);
  return date;
};
/**
* This function adds years to a date, returns Date Object
*
* @param {Date} date the date object to add to
* @param {int} years number of years to add
*/
const addYears = (date, years) => {
  date.setFullYear(date.getFullYear() + years);
  return date;
};
/**
* This function formats a date into YYYY-MM-DD, returns as string
* This is the standard for our APIs and for Date Objects
*
* @param {Date} date the Date Object to format
*/
const formatDate = (date) => {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join("-");
};
/**
* This function formats a date into MM/DD/YYYY, returns as string
* This is used normally for UI purposes
*
* @param {Date} date the Date Object to format
*/
const formatDateUS = (date) => {
  return [
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
    date.getFullYear()
  ].join("/");
};
/**
* This function formats a date into MM/YYYY, returns as string
* This is used normally for UI purposes
*
* @param {Date} date the Date Object to format
*/
const formatMonthYear = (date) => {
  return [
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear()
  ].join("/");
};
/**
* This functions formats the timezone offset to ensure the current date is returned when a new date is created
* new Date('1999-05-14') will often return the day prior, '1999-05-13' depending on the time of day and the timezone
* that the user is performing the action resides on. To eliminate that potential this function removes the timezone offset
* by adding it to the date value.
*
* Returns Date Object
*
* @param {Date} date the Date Object to format timezone on
*/
const formatTimezone = (date) => {
  return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
};
/**
* Checks if the first Date provided occurs before the second date provided
* If dateToCompare comes before date, return true
* Can receive Date Object, String in YYYY-MM-DD format, or Int value in Milliseconds
*
* @param {Date/Int/String} dateToCompare date to check
* @param {Date/Int/String} date date to see if before
*/
const isBefore = (dateToCompare, date) => {
  return dateToCompare < date;
};
/**
* Checks if the first Date provided occurs before or on the same day as the second date provided
* If dateToCompare comes before or on the same day as date, return true
* Can receive Date Object, String in YYYY-MM-DD format, or Int value in Milliseconds
*
* @param {Date/Int/String} dateToCompare date to check
* @param {Date/Int/String} date date to see if before
*/
const isBeforeOrSame = (dateToCompare, date) => {
  return dateToCompare <= date;
};
/**
* Checks if the first Date provided occurs after the second date provided
* If dateToCompare comes after date, return true
* Can receive Date Object, String in YYYY-MM-DD format, or Int value in Milliseconds
*
* @param {Date/Int/String} dateToCompare date to check
* @param {Date/Int/String} date date to see if after
*/
const isAfter = (dateToCompare, date) => {
  return dateToCompare > date;
};
/**
* Checks if the first Date provided occurs after or on the same day as the second date provided
* If dateToCompare comes after or on the same day as date, return true
* Can receive Date Object, String in YYYY-MM-DD format, or Int value in Milliseconds
*
* @param {Date/Int/String} dateToCompare date to check
* @param {Date/Int/String} date date to see if after
*/
const isAfterOrSame = (dateToCompare, date) => {
  return dateToCompare >= date;
};
/**
* This function determines the amount of days between two dates, returns int
* If D1 comes Before D2, a negative value is returned
* Rounds down if
*
* @param {Date} d1 first date
* @param {Date} d2 second date
*/
const dayDiff = (d1, d2) => {
  const diff = ((d1 - d2) / (1000 * 60 * 60 * 24));
  if (diff < 0) {
    return Math.ceil(diff);
  }
  return Math.floor(diff);
};
/**
* This function determines the amount of months between two dates, returns int
* If D1 comes Before D2, a negative value is returned
* Rounds down if
*
* @param {Date} d1 first date
* @param {Date} d2 second date
*/
const monthDiff = (d1, d2) => {
  let months = (d1.getFullYear() - d2.getFullYear()) * 12;
  months += d1.getMonth();
  months -= d2.getMonth();
  if (d1 < d2 && d1.getDate() > d2.getDate()) {
    months -= 1;
  } else if (d1 > d2 && d1.getDate() < d2.getDate()) {
    months -= 1;
  }
  return months;
};
/**
* This function determines the amount of years between two dates, returns int
* If D1 comes Before D2, a negative value is returned
* If Floor is true, Rounds down value 11 months = 0 years
* If False, return float
*
* @param {Date} d1 first date
* @param {Date} d2 second date
* @param {boolean} floor Floor down to nearest whole value, if false return float
*/
const yearDiff = (d1, d2, floor = true) => {
  const diff = monthDiff(d1, d2) / 12;
  if (floor) {
    if (diff < 0) {
      return Math.ceil(diff);
    }
    return Math.floor(diff);
  }
  return diff;
};
/**
* This function separates the year, month, and day from a Date Object. Returns array of ints.
*
* @param {Date} date Date object to separate values from
*/
const separateDateVals = (date) => {
  date = formatTimezone(new Date(date));
  return [date.getFullYear(), date.getMonth() + 1, date.getDate()];
};

module.exports = {
  padTo2Digits,
  formatDate,
  formatDateUS,
  subtractMonths,
  subtractYears,
  formatTimezone,
  formatMonthYear,
  isBeforeOrSame,
  isBefore,
  isAfter,
  isAfterOrSame,
  dayDiff,
  monthDiff,
  yearDiff,
  addDays,
  addMonths,
  addYears,
  separateDateVals,
  convertToDateFormat
};




  

