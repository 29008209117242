const redirectLogin = (url) => {
  const link = document.createElement("a");
  document.body.appendChild(link);

  link.href = url;
  link.id = "insuranceLoginLink";

  document.getElementById("insuranceLoginLink").click();
};

module.exports = redirectLogin;
