import React from "react";

const NoAccess = () => {
  return (
    <div className="container" data-testid="iip-access-denied">
      <div className="mm-spacing">
        <h1 className="h1">We&apos;re sorry</h1>
        <p>
          Based on the information provided, we are unable to process your
          request.
        </p>
        <p>
          Please contact our MassMutual Service Team at{" "}
          <a href="tel:1-800-655-2654">1-800-655-2654</a> or email at{" "}
          <a href="mailto: COLI@massmutual.com">COLI@massmutual.com</a>.
        </p>
      </div>
    </div>
  );
};

export default NoAccess;
