import React from "react";
import { Link } from "react-router-dom";

const pvtClientGroup = (props) => {
  const { path } = props;
  return (
    <div className="mm-spacing">
      <h2 className="h2" data-testid="iip-sub-heading">
        Private Client Group
      </h2>
      <div className="card">
        <div className="card-body">
          <ul className="mm-actions-list">
            <li>
              <Link to={`${path}/product/sl14`} data-testid="iip-link">
                Strategic Life<sup>&reg;</sup> &nbsp; Prestige
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default pvtClientGroup;
