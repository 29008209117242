import React from "react";
import FundLinks from "../FundLinks/FundLinks";
const FundName = (props) => {
  const { details, oktaUser, auth0User } = props;
  return details.map((fund) => {
    return (
      <div key={fund.cusip}>
        <h3 className="h4" data-testid="iip-fund-name">
          {fund.fundShareClass}
        </h3>
        <FundLinks
          fundClass={fund.fundShareClass}
          fund={fund}
          oktaUser={oktaUser}
          auth0User={auth0User}
        />
        <hr />
      </div>
    );
  });
};

export default FundName;
