
import React from "react";
import Modal from "react-bootstrap/Modal";

const TimeOutModal = (props) => {
  const {
    showTimeoutModal, stayLoggedIn, logoutFromAuth0, timer
  } = props;
  return (
    <Modal show={showTimeoutModal} onHide={stayLoggedIn}>
      <Modal.Header>
        <Modal.Title>Your session is about to expire.</Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={stayLoggedIn}
        ></button>
      </Modal.Header>

      <Modal.Body>
        <p>
          You&apos;ve been inactive for a while. For your security, we will
          automatically log you out after <b>{timer}</b> minutes.
        </p>
        <p>Do you want to stay logged in?</p>
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          data-dismiss="modal"
          onClick={stayLoggedIn}
        >
          Stay logged in
        </button>
        <button
          type="button"
          className="btn btn-link"
          data-dismiss="modal"
          onClick={logoutFromAuth0}
        >
          Log out
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default TimeOutModal;

