import parse from "html-react-parser";
import React from "react";

const DashBoardTopContent = (props) => {
  const { content, firstName, sl14Only } = props;
  const dashboardText = sl14Only
    ? content.dashboardSl14OnlyText
    : content.dashboardText;
  return (
    <>
      <h1 className="h1" data-testid="iip-heading">
        Welcome, {firstName}.
      </h1>
      <h2 className="h2" data-testid="iip-sub-heading">
        {content.dashboardTitle}
      </h2>
      <div data-testid="iip-paragraph">{parse(dashboardText)}</div>
    </>
  );
};

export default DashBoardTopContent;
