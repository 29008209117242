import React from "react";

import Logo from "../../images/logo-header.png";

const Header = (props) => {
  const { oktaUser } = props;
  if (Object.keys(oktaUser).length > 0) {
    return (
      <nav
        role="navigation"
        title="IIP Navbar - Okta"
        className="mm-navbar--auth"
        data-testid="iip-navbar-okta"
      >
        <div className="navbar navbar-expand-xl">
          <div className="container">
            <div className="navbar-brand" data-testid="iip-header-logo">
              <img src={Logo} alt="MM IIP Logo" />
            </div>
            <div className="navbar-nav">
              <span className="navbar-text" data-testid="iip-okta-user">
                {oktaUser.firstname} {oktaUser.lastname} : {oktaUser.mmid}
              </span>
            </div>
          </div>
        </div>
      </nav>
    );
  }
  return (
    <nav
      role={"navigation"}
      title="IIP Navbar"
      className="mm-navbar--auth"
      data-testid="iip-navbar"
    >
      <div className="navbar navbar-expand-lg">
        <div className="container">
          <div className="navbar-brand" data-testid="iip-header-logo">
            <img src={Logo} alt="MM IIP Logo" />
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#auth-nav--dropdowns"
            aria-controls="auth-nav--dropdowns"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="auth-nav--dropdowns">
            <div className="navbar-nav">
              <div className="nav-item">
                <a
                  className="nav-link"
                  href="/institutional-insurance/loginsettings"
                  data-testid="iip-login-settings"
                >
                  <span className="icon-settings-white"></span>Login Settings
                </a>
              </div>
              <div className="nav-item">
                <a
                  onClick={() => sessionStorage.removeItem("isSessionActive")}
                  className="nav-link"
                  href="/institutional-insurance/logout"
                  data-testid="iip-logout"
                >
                  <span className="icon-logout-white"></span>Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
