import React from "react";

const TopContent = (props) => {
  const { content, showSlProduct } = props;

  return (
    <>
      <div className="col-sm-8 row">
        <h3 className="h3" data-testid="iip-sub-heading">
          {showSlProduct.includes("sl14")
            ? content.sl14SubTitle
            : content.subTitle}
          &nbsp;
        </h3>
      </div>
      {showSlProduct.includes("sl20") && (
        <div className="col-sm-4">
          <a
            data-testid="iip-link"
            href={content.monthlyPerformancesl20PDF}
            target={"_blank"}
            rel="noreferrer"
          >
            <span className="icon-external-link icon-sm"></span>{" "}
            {content.pdfTitle}
          </a>
        </div>
      )}
    </>
  );
};

export default TopContent;
