import React from "react";

const CommonExternalLink = (props) => {
  const { content, prefixUrl, heading } = props;

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="h4" data-testid="iip-sub-heading">
          {heading}
        </h4>
        <ul className="mm-actions-list">
          {content.map((individualObject, index) => {
            return (
              <li key={index} data-testid="iip-link">
                <span className="icon-external-link icon-sm"></span>
                <a
                  href={prefixUrl + individualObject.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" " + individualObject.type}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CommonExternalLink;
