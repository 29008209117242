import axios from "axios";

const onLinkClick = (e, fundClass, oktaUser, auth0User) => {
  let appEnvironment = "prod";
  if (window.location.hostname.includes("local")) {
    appEnvironment = "local";
  }
  if (window.location.hostname.includes("dev")) {
    appEnvironment = "dev";
  }
  if (window.location.hostname.includes("qa")) {
    appEnvironment = "qa";
  }
  e.preventDefault();
  axios
    .post("/institutional-insurance/saveInfoToSumo", {
      data: {
        fundClass,
        pdfFile: e.target.innerText,
        user: oktaUser.mmid || auth0User.email,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        if (appEnvironment !== "prod") {
          console.log("No server error. No logs pushed to Sumo.");
        }
      }
    })
    .catch((error) => {
      if (appEnvironment === "prod") {
        console.log("Server error while saving the log events.");
      } else {
        console.log(error.message);
      }
    });
  window.open(e.target.href, "_blank");
};

export default onLinkClick;
