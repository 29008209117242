import parse from "html-react-parser";
import React from "react";

const ServiceMarketingTools = (props) => {
  const { contentTools, prefixUrl, heading, onlyBoli, boliProducts } = props;
  const BOLI = "BOLI";
  const COLI = "COLI";

  return (
    <div className="mm-spacing">
      <h2 className="h2" data-testid="iip-sub-heading">
        {heading}
      </h2>
      <div className="card">
        <div className="card-body">
          {!onlyBoli && (
            <div className="mm-spacing--minor">
              {heading === "Marketing Tools" && (
                <h4 className="h4" data-testid="iip-sub-heading">
                  {COLI}
                </h4>
              )}
              <ul className="mm-actions-list">
                {contentTools.list.map((serviceMarketool, index) => {
                  return (
                    <li key={index}>
                      <a
                        href={prefixUrl + serviceMarketool.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {parse(serviceMarketool.tool)}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {boliProducts && (
            <div>
              {heading === "Marketing Tools" && (
                <h4 className="h4" data-testid="iip-sub-heading">
                  {BOLI}
                </h4>
              )}
              <ul className="mm-actions-list">
                {contentTools.boliList &&
                  boliProducts &&
                  contentTools.boliList.map((Marketool, index) => {
                    return (
                      <li key={index}>
                        <a
                          href={prefixUrl + Marketool.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {parse(Marketool.tool)}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceMarketingTools;
