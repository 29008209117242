import parse from "html-react-parser";
import React from "react";

const Disclaimer = (props) => {
  const { content } = props;
  var disclaimer = parse(content);

  return (
    <div className="container">
      <div className="mm-spacing" data-testid="iip-disclaimer">
        {disclaimer}
      </div>
    </div>
  );
};

export default Disclaimer;
