/* eslint-disable no-undef */
const tealiumPageViewEvent = (pageName, pageCategory, contentType) => {
  if (typeof utag !== "undefined") {
    utag.view({
      content_type: contentType || "",
      page_category: pageCategory || "",
      page_name: pageName || "",
      site_id: "MM-Insurance"
    });
  }
};

export default tealiumPageViewEvent;
