import React from "react";
import onLinkClick from "../../utils/onClickLink";

const FundLinks = (props) => {
  const { fund, fundClass, oktaUser, auth0User } = props;

  return (
    <div data-testid="iip-list">
      <ul className="separated-list">
        <li>
          <a
            href={fund.summaryProspectus}
            onClick={(e) => onLinkClick(e, fundClass, oktaUser, auth0User)}
            target={"_blank"}
            rel="noreferrer"
            data-testid="iip-summary-prospectus"
          >
            Summary Prospectus
          </a>
        </li>
        <li>
          <a
            href={fund.prospectus}
            onClick={(e) => onLinkClick(e, fundClass, oktaUser, auth0User)}
            target={"_blank"}
            rel="noreferrer"
            data-testid="iip-prospectus"
          >
            Prospectus
          </a>
        </li>
        <li>
          <a
            href={fund.annualReport}
            onClick={(e) => onLinkClick(e, fundClass, oktaUser, auth0User)}
            target={"_blank"}
            rel="noreferrer"
            data-testid="iip-annual-report"
          >
            Annual Report
          </a>
        </li>
        <li>
          <a
            href={fund.semiAnnualReport}
            onClick={(e) => onLinkClick(e, fundClass, oktaUser, auth0User)}
            target={"_blank"}
            rel="noreferrer"
            data-testid="iip-semi-annual-report"
          >
            Semi-Annual Report
          </a>
        </li>
        <li>
          <a
            href={fund.additionalInformation}
            onClick={(e) => onLinkClick(e, fundClass, oktaUser, auth0User)}
            target={"_blank"}
            rel="noreferrer"
            data-testid="iip-additional-info"
          >
            Statement of Additional Information
          </a>
        </li>
      </ul>
      <ul className="separated-list">
        {fund.q1 && (
          <li>
            {fund.q1 && fund.q3 && (<span className="copy-md" data-testid="iip-holdings">Portfolio Holdings: </span>)}
            <a
              href={fund.q1}
              onClick={(e) => onLinkClick(e, fundClass, oktaUser, auth0User)}
              target={"_blank"}
              rel="noreferrer"
              data-testid="iip-q1"
            >
              Q1
            </a>
          </li>
        )}
        {fund.q3 && (
          <li>
            <a
              href={fund.q3}
              onClick={(e) => onLinkClick(e, fundClass, oktaUser, auth0User)}
              target={"_blank"}
              rel="noreferrer"
              data-testid="iip-q3"
            >
              Q3
            </a>
          </li>
        )}
        {fund.factSheet && (
          <li>
            <a
              href={fund.factSheet}
              onClick={(e) => onLinkClick(e, fundClass, oktaUser, auth0User)}
              target={"_blank"}
              rel="noreferrer"
              data-testid="iip-fact-sheet"
            >
              Fact Sheet
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

export default FundLinks;
